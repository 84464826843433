import Vimeo from '@u-wave/react-vimeo';
import classNames from 'classnames';
import gsap from 'gsap';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { pageMount, pageWatchLoaded } from '../state/page';

const WatchPage = ({ onMount = () => {}, onPageWatchLoaded = () => {} }) => {
  const ref = useRef(null);
  const vimeoRef = useRef(null);
  const [showBackground, setShowBackground] = useState(true);
  const [animation, setAnimation] = useState(true);
  const [vimeoPlayer, setVimeoPlayer] = useState(null);
  const [videoMuted, setVideoMuted] = useState(true);

  /* Effect handling page mount */
  useEffect(() => {
    onMount();

    setTimeout(onPageWatchLoaded, 2500);

    const tl = gsap.timeline();

    gsap.set(ref.current, {
      x: window.innerWidth - window.innerWidth * 0.2,
      y: window.innerHeight,
      scale: 0,
    });

    tl.to(ref.current, {
      duration: 3,
      scale: 1,
      x: 0,
      y: 0,
      ease: 'power1.in',
      rotate: 358 * 2,
      onComplete: () => {
        setAnimation(false);
        setTimeout(() => setShowBackground(false), 4000);
      },
    });
  }, []);

  const handleOnMuteClick = async () => {
    const muted = await vimeoPlayer.getMuted();
    setVideoMuted(!muted);
    vimeoPlayer.setMuted(!muted);
  };

  const handleSetVimeoPlayer = (vp) => {
    setVimeoPlayer(vp);
  };

  return (
    <div>
      <div className="page page--full page--center">
        <div className="vimeo" ref={ref}>
          <div
            className={classNames('vimeo__player', {
              'vimeo__player--no-background': !showBackground,
            })}
          >
            {animation ? (
              <div className="vimeo__placeholder" />
            ) : (
              <React.Fragment>
                <Vimeo
                  id="vimeo-video"
                  ref={vimeoRef}
                  autoplay
                  loop
                  muted
                  controls={false}
                  video="484140157"
                  responsive
                  onReady={handleSetVimeoPlayer}
                />
                {vimeoPlayer && (
                  <button type="button" className="vimeo__button" onClick={handleOnMuteClick}>
                    {`Sound ${videoMuted ? 'on' : 'off'}`}
                  </button>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, (dispatch) => ({
  onMount: () => {
    dispatch(pageMount('watch'));
  },
  onPageWatchLoaded: () => {
    dispatch(pageWatchLoaded());
  },
}))(WatchPage);
